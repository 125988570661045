import { RouteConfig } from 'vue-router'
import { BottomNavigationPages } from '@/components/BottomNavigationBar.types'

export enum CardNames {
    CREDIT_SCORE = 'CREDIT_SCORE',
    HOME_VALUE = 'HOME_VALUE',
    AVEN_HOME_AD = 'AVEN_HOME_AD',
    AVEN_AUTO_AD = 'AVEN_AUTO_AD',
    EXPERIAN_BOOST = 'EXPERIAN_BOOST',
    CASH_AND_STOCKS = 'CASH_AND_STOCKS',
    LIABILITIES = 'LIABILITIES',
    NEIGHBORHOOD_REPORT = 'NEIGHBORHOOD_REPORT',
    ENERGY_PRICES = 'ENERGY_PRICES',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    TOP_MERCHANTS = 'TOP_MERCHANTS',
    VEHICLE_VALUE = 'VEHICLE_VALUE',
    SWEEPSTAKES = 'SWEEPSTAKES',
    CREDIT_SCORE_TIPS = 'CREDIT_SCORE_TIPS',
    CREDIT_BUILDER = 'CREDIT_BUILDER',
    RECENT_TRANSACTIONS = 'RECENT_TRANSACTIONS',
    INVESTMENT_HOLDINGS = 'INVESTMENT_HOLDINGS',
    NOTABLE_TRADES = 'NOTABLE_TRADES',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    HOME_EQUITY = 'HOME_EQUITY',
    HOMES_FOR_SALE = 'HOMES_FOR_SALE',
    SPEND_TRACKING = 'SPEND_TRACKING',
    SUBSCRIPTIONS_UPSELL = 'SUBSCRIPTIONS_UPSELL',
    HOMES_FOR_RENT = 'HOMES_FOR_RENT',
    CRAIGSLIST_LISTINGS = 'CRAIGSLIST_LISTINGS',
    REQUEST_LIEN_REPORT = 'REQUEST_LIEN_REPORT',
    CREDIT_CARD_COMPARISON = 'CREDIT_CARD_COMPARISON',
}

// Keep in sync with appRoutePaths in aven_backend/src/controller/createAvenAdvisorAccountFromOriginationController.ts
export const appRoutePaths = {
    APP: `/app`,
    TODAY: `/app/today`,
    ACCOUNT: `/app/account`,
    REFERRAL_CONTACT_LIST: '/app/referralContactList',
    REFERRAL_SEND_OPTIONS: '/app/sendReferralLink',
    REFERRAL_DEPOSIT: '/app/referralDeposit', // if you change, please update SOURCES_PATH_TO_KEEP_IN_WEB_VIEW in aven_apps/aven_score/src/Web/web.tsx
    DEPOSIT_CONFIRMED: '/app/depositConfirmed',
    SSN_VERIFICATION: '/app/ssnVerification',
    EXPERIAN_BOOST_DATA_SHARING_CONSENT: '/app/experianBoostDataSharingConsent',
    PLAID_LINK: '/app/plaidLink', // if you change, please update SOURCES_PATH_TO_KEEP_IN_WEB_VIEW in aven_apps/aven_score/src/Web/web.tsx
    OFFERS: '/app/offers',
    SIGNED_IN_DEVELOPER_SETTINGS: '/app/signedInDeveloperSettings',
    SIGNED_OUT_DEVELOPER_SETTINGS: '/app/signedOutDeveloperSettings',
    CREDIT_SCORE: '/app/creditScore',
    CREDIT_SCORE_TIPS: '/app/creditScoreTips',
    HOME_VALUE: '/app/homeValue',
    AVEN_HOME_AD: '/app/avenHome',
    AVEN_AUTO_AD: '/app/avenAuto',
    EXPERIAN_BOOST: '/app/experianBoost',
    CASH_AND_STOCKS: '/app/cashAndStocks',
    LIABILITIES: '/app/liabilities',
    HAUS_Q_AND_A: '/app/hausQAndA',
    NEIGHBORHOOD_REPORT: '/app/neighborhoodReport',
    NOTIFICATION_PERMISSION: '/app/notificationPermission',
    APPLY_AVEN_CARD: '/app/applyAvenCard',
    ENERGY_PRICES: '/app/energyPrices',
    CONNECT_PLAID_ACCOUNT: `/app/connectPlaidAccount`,
    EXTERNAL_LINK: '/app/externalLink',
    TOP_MERCHANTS: '/app/topMerchants',
    VEHICLE_VALUE: '/app/vehicleValue',
    ADDRESS_UPDATE: `/app/address`, // CURRENTLY ADDRESS_UPDATE ONLY WORKS FOR EMPLOYEES
    SSN_DOB_UPDATE: `/app/ssnDobUpdate`, // CURRENTLY THIS ONLY WORKS FOR EMPLOYEES
    DELETE_ACCOUNT_CONFIRMATION: '/app/deleteAccountConfirmation',
    DELETE_ACCOUNT_FEEDBACK: '/app/deleteAccountFeedback',
    EDIT_ACCOUNT_INFORMATION: '/app/editAccountInformation',
    EDIT_ACCOUNT_INFORMATION_OTP: '/app/editAccountInformationOtp',
    SWEEPSTAKES: '/app/sweepstakes',
    PLAID_UPSELL: '/app/plaidUpsell',
    ACCOUNT_INFORMATION: '/app/accountInformation',
    CU_SURVEY_UPSELL: '/app/cuSurveyUpsell',
    CU_SURVEY: '/app/cuSurvey',
    AVEN_OG_DISCLOSURES: '/app/avenOGDisclosures',
    CREDIT_BUILDER: '/app/buildCredit',
    PAYOUT_HISTORY: '/app/payoutHistory',
    RECENT_TRANSACTIONS: '/app/recentTransactions',
    PLAID_ACCOUNTS_FOR_INSTITUTION: '/app/plaidAccountsForInstitution',
    PLAID_INVESTMENT_HOLDINGS: '/app/plaidInvestmentHoldings',
    NOTABLE_TRADES: '/app/notableTrades',
    SUBSCRIPTIONS: '/app/subscriptions',
    HOME_EQUITY: '/app/homeEquity',
    HOMES_FOR_SALE: '/app/homesForSale',
    HOMES_FOR_RENT: '/app/homesForRent',
    CREDIT_SCORE_FULL_PAGE: '/app/creditScoreFullPage',
    SPEND_TRACKING: '/app/spendTracking',
    SUBSCRIPTIONS_UPSELL: '/app/subscriptionsUpsell',
    CRAIGSLIST_LISTINGS: '/app/craigslistListings',
    SUBSCRIPTION_DETAIL_PAGE: '/app/subscriptionDetailPage',
    NOTIFICATION_CENTER: '/app/notificationCenter',
    LIEN_REPORT: '/app/lienReport',
    CREDIT_CARD_COMPARISION: '/app/creditCardComparison',
}

export enum OtpPurpose {
    PHONE_NUMBER_CHANGE = 'PHONE_NUMBER_CHANGE',
}

export const appRoutes: RouteConfig[] = [
    {
        path: appRoutePaths.APP,
        component: () => import(/* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/pages/app/Dashboard.vue'),
        meta: { public: false, requiresAuth: true },
        props: { startPage: BottomNavigationPages.TODAY },
        name: BottomNavigationPages.TODAY,
    },
    {
        path: appRoutePaths.TODAY,
        component: () => import(/* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/pages/app/Dashboard.vue'),
        meta: { public: false, requiresAuth: true },
        redirect: { name: BottomNavigationPages.TODAY },
    },
    {
        path: appRoutePaths.ACCOUNT,
        component: () => import(/* webpackChunkName: "account", webpackPrefetch: true */ '@/pages/app/Account.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.REFERRAL_CONTACT_LIST,
        component: () => import(/* webpackChunkName: "referral-contact-list", webpackPrefetch: true */ '@/pages/app/ReferralContactList.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.ADDRESS_UPDATE,
        component: () => import(/* webpackChunkName: "address", webpackPrefetch: true */ '@/pages/onboarding/Address.vue'),
        meta: { public: false, requiresAuth: true },
        props: { updateExistingAddress: true },
    },
    {
        path: appRoutePaths.SSN_DOB_UPDATE,
        component: () => import(/* webpackChunkName: "update-ssn-dob", webpackPrefetch: true */ '@/pages/app/UpdateSsnDob.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.REFERRAL_SEND_OPTIONS,
        component: () => import(/* webpackChunkName: "referral-send-options", webpackPrefetch: true */ '@/pages/app/ReferralSendOptions.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.REFERRAL_DEPOSIT,
        component: () => import(/* webpackChunkName: "referral-deposit", webpackPrefetch: true */ '@/pages/app/ReferralDeposit.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.DEPOSIT_CONFIRMED,
        component: () => import(/* webpackChunkName: "referral-confirmed", webpackPrefetch: true */ '@/pages/app/ReferralConfirmed.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "ssn-verification", webpackPrefetch: true */ '@/pages/onboarding/SsnVerification.vue'),
        meta: { public: false, requiresAuth: true },
        props: { nextRoutePath: appRoutePaths.TODAY },
    },
    {
        path: appRoutePaths.EXPERIAN_BOOST_DATA_SHARING_CONSENT,
        component: () => import(/* webpackChunkName: "experian-boost-data-sharing-consent", webpackPrefetch: true */ '@/pages/ExperianBoostDataSharingConsent.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.PLAID_LINK,
        component: () => import(/* webpackChunkName: "plaid-link", webpackPrefetch: true */ '@/pages/app/PlaidLink.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.OFFERS,
        component: () => import(/* webpackChunkName: "offers", webpackPrefetch: true */ '@/pages/app/AllCreditOffers.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.CREDIT_SCORE,
        component: () => import(/* webpackChunkName: "credit-score", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.CREDIT_SCORE },
    },
    {
        path: appRoutePaths.CREDIT_SCORE_FULL_PAGE,
        component: () => import(/* webpackChunkName: "credit-score-full-page", webpackPrefetch: true */ '@/pages/app/CreditScorePage.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.SUBSCRIPTION_DETAIL_PAGE,
        component: () => import(/* webpackChunkName: "subscription-detail-page", webpackPrefetch: true */ '@/pages/app/SubscriptionDetailPage.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.HOME_VALUE,
        component: () => import(/* webpackChunkName: "home-value", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.HOME_VALUE },
    },
    {
        path: appRoutePaths.AVEN_HOME_AD,
        component: () => import(/* webpackChunkName: "home-ad", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.AVEN_HOME_AD },
    },
    {
        path: appRoutePaths.AVEN_AUTO_AD,
        component: () => import(/* webpackChunkName: "auto-ad", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.AVEN_AUTO_AD },
    },
    {
        path: appRoutePaths.EXPERIAN_BOOST,
        component: () => import(/* webpackChunkName: "experian-boost", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.EXPERIAN_BOOST },
    },
    {
        path: appRoutePaths.CASH_AND_STOCKS,
        component: () => import(/* webpackChunkName: "cash-and-stock", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.CASH_AND_STOCKS },
    },
    {
        path: appRoutePaths.SIGNED_IN_DEVELOPER_SETTINGS,
        component: () => import(/* webpackChunkName: "developer-settings", webpackPrefetch: true */ '@/pages/app/DeveloperSettings.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.SIGNED_OUT_DEVELOPER_SETTINGS,
        component: () => import(/* webpackChunkName: "developer-settings", webpackPrefetch: true */ '@/pages/app/DeveloperSettings.vue'),
        meta: { public: false, requiresAuth: false },
    },
    {
        path: appRoutePaths.HAUS_Q_AND_A,
        component: () => import(/* webpackChunkName: "haus-q-and-a", webpackPrefetch: true */ '@/pages/app/QAndASubmitPage.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.LIABILITIES,
        component: () => import(/* webpackChunkName: "liabilities", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.LIABILITIES },
    },
    {
        path: appRoutePaths.NEIGHBORHOOD_REPORT,
        component: () => import(/* webpackChunkName: "neighborhoodReport", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.NEIGHBORHOOD_REPORT },
    },
    {
        path: appRoutePaths.NOTIFICATION_PERMISSION,
        component: () => import(/* webpackChunkName: "notification", webpackPrefetch: true */ '@/pages/Notification.vue'),
        meta: { public: false, requiresAuth: true },
        props: { nextRoutePath: appRoutePaths.TODAY },
    },
    {
        path: appRoutePaths.APPLY_AVEN_CARD,
        component: () => import(/* webpackChunkName: "apply-aven-card", webpackPrefetch: true */ '@/pages/app/ApplyAvenCard.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.CONNECT_PLAID_ACCOUNT,
        component: () => import(/* webpackChunkName: "onboarding-plaid-connect", webpackPrefetch: true */ '@/pages/onboarding/ConnectPlaidAccount.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.ENERGY_PRICES,
        component: () => import(/* webpackChunkName: "energyPrices", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.ENERGY_PRICES },
    },
    {
        path: appRoutePaths.EXTERNAL_LINK,
        component: () => import(/* webpackChunkName: "energyPrices", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.EXTERNAL_LINK },
    },
    {
        path: appRoutePaths.VEHICLE_VALUE,
        component: () => import(/* webpackChunkName: "vehicleValue", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.VEHICLE_VALUE },
    },
    {
        path: appRoutePaths.DELETE_ACCOUNT_CONFIRMATION,
        component: () => import(/* webpackChunkName: "deleteAccountConfirmation", webpackPrefetch: true */ '@/pages/app/DeleteAccountConfirmation.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.DELETE_ACCOUNT_FEEDBACK,
        component: () => import(/* webpackChunkName: "deleteAccountConfirmation", webpackPrefetch: true */ '@/pages/app/DeleteAccountFeedback.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.EDIT_ACCOUNT_INFORMATION,
        component: () => import(/* webpackChunkName: "editAccountInformation", webpackPrefetch: true */ '@/pages/app/EditAccountInformation.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.TOP_MERCHANTS,
        component: () => import(/* webpackChunkName: "topMerchants", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.TOP_MERCHANTS },
    },
    {
        path: appRoutePaths.SWEEPSTAKES,
        component: () => import(/* webpackChunkName: "sweepstakes", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.SWEEPSTAKES },
    },
    {
        path: appRoutePaths.ACCOUNT_INFORMATION,
        component: () => import(/* webpackChunkName: "accountInformation", webpackPrefetch: true */ '@/pages/app/AccountInformation.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.CREDIT_SCORE_TIPS,
        component: () => import(/* webpackChunkName: "creditScoreTips", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.CREDIT_SCORE_TIPS },
    },
    {
        path: appRoutePaths.PLAID_UPSELL,
        component: () => import(/* webpackChunkName: "plaidUpsell", webpackPrefetch: true */ '@/pages/onboarding/PlaidUpsell.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.CU_SURVEY_UPSELL,
        component: () => import(/* webpackChunkName: "cuSurveyUpsell", webpackPrefetch: true */ '@/pages/onboarding/CuSurveyUpsell.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.CU_SURVEY,
        component: () => import(/* webpackChunkName: "cuSurvey", webpackPrefetch: true */ '@/pages/onboarding/CuSurvey.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.AVEN_OG_DISCLOSURES,
        component: () => import(/* webpackChunkName: "avenOGDisclosures", webpackPrefetch: true */ '@/pages/app/AvenOGDisclosures.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.EDIT_ACCOUNT_INFORMATION_OTP,
        component: () => import(/* webpackChunkName: "otp-verification", webpackPrefetch: true */ '@/pages/OtpVerification.vue'),
        meta: { public: false, requiresAuth: true },
        props: { purpose: OtpPurpose.PHONE_NUMBER_CHANGE },
    },
    {
        path: appRoutePaths.PAYOUT_HISTORY,
        component: () => import(/* webpackChunkName: "payoutHistory", webpackPrefetch: true */ '@/pages/app/PayoutHistory.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.RECENT_TRANSACTIONS,
        component: () => import(/* webpackChunkName: "recentTransactions", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.RECENT_TRANSACTIONS },
    },
    {
        path: appRoutePaths.PLAID_INVESTMENT_HOLDINGS,
        component: () => import(/* webpackChunkName: "plaidInvestmentHoldings", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.INVESTMENT_HOLDINGS },
    },
    {
        path: appRoutePaths.PLAID_ACCOUNTS_FOR_INSTITUTION,
        component: () => import(/* webpackChunkName: "plaidAccounts", webpackPrefetch: true */ '@/pages/app/PlaidAccountsForInstitution.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.NOTABLE_TRADES,
        component: () => import(/* webpackChunkName: "plaidInvestmentHoldings", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.NOTABLE_TRADES },
    },
    {
        path: appRoutePaths.SUBSCRIPTIONS_UPSELL,
        component: () => import(/* webpackChunkName: "subscriptionsUpsell", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.SUBSCRIPTIONS_UPSELL },
    },
    {
        path: appRoutePaths.SUBSCRIPTIONS,
        component: () => import(/* webpackChunkName: "subscriptions", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.SUBSCRIPTIONS },
    },
    {
        path: appRoutePaths.HOME_EQUITY,
        component: () => import(/* webpackChunkName: "homeEquity", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.HOME_EQUITY },
    },
    {
        path: appRoutePaths.HOMES_FOR_SALE,
        component: () => import(/* webpackChunkName: "homesForSale", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.HOMES_FOR_SALE },
    },
    {
        path: appRoutePaths.SPEND_TRACKING,
        component: () => import(/* webpackChunkName: "spendTracking", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.SPEND_TRACKING },
    },
    {
        path: appRoutePaths.HOMES_FOR_RENT,
        component: () => import(/* webpackChunkName: "homesForRent", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.HOMES_FOR_RENT },
    },
    {
        path: appRoutePaths.CRAIGSLIST_LISTINGS,
        component: () => import(/* webpackChunkName: "craigslistListings", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.CRAIGSLIST_LISTINGS },
    },
    {
        path: appRoutePaths.CREDIT_CARD_COMPARISION,
        component: () => import(/* webpackChunkName: "creditCardComparison", webpackPrefetch: true */ '@/pages/app/SingleCardPage.vue'),
        meta: { public: false, requiresAuth: true },
        props: { cardToDisplay: CardNames.CREDIT_CARD_COMPARISON },
    },
    {
        path: appRoutePaths.NOTIFICATION_CENTER,
        component: () => import(/* webpackChunkName: "notificationCenter", webpackPrefetch: true */ '@/pages/app/NotificationCenter.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: appRoutePaths.LIEN_REPORT,
        component: () => import(/* webpackChunkName: "lienReport", webpackPrefetch: true */ '@/pages/app/LienReport.vue'),
        meta: { public: false, requiresAuth: true },
    },
]
