<template>
    <onboarding-layout
        :error-text="errorText"
        :has-back="false"
    >
        <div class="d-flex flex-column align-items-center">
            <img
                src="@/assets/images/advisor-logo.svg"
                alt="Aven Logo"
                class="w-50"
            >
            <img
                src="@/assets/images/landing-promo-image.png"
                alt="Aven promo image"
                class="w-100"
            >
            <h5 class="fw-light text-center">
                Enter your mobile number to get your financial profile
            </h5>
        </div>
        <form-container
            id="SignInForm"
            ref="signInFormRef"
        >
            <form-field-phone
                id="phoneNumber"
                class="mt-2"
                label="Mobile Number"
                ref="phoneField"
                placeholder="Mobile Number"
                name="phoneNumber"
                input-type="number"
                v-model="phoneNumber"
                data-testid="phone-number"
                autocomplete="off"
                focus-event="event_aven_advisor_phone_number_field_focus"
                @input="onPhoneNumberInput"
            />
            <form-field
                id="inviteCode"
                class="mt-2"
                v-model="inviteCode"
                v-if="shouldShowPaidReferralsOption"
                name="inviteCode"
                placeholder="Invite Code (Optional)"
                label="Invite Code (Optional)"
                data-testid="invite-code"
            />
            <a
                class="btn btn-link gray-link small fw-normal p-2 w-100"
                data-testid="login-with-email-button"
                @click="handleLoginWithEmailClick"
                @keydown="handleLoginWithEmailClick"
            >
                Login With Email
            </a>
            <form-button
                :submitting="submitting"
                :disabled="phoneNumberCannotBeSubmitted"
                class="mt-1"
                @click="handleOnSubmit"
                label="Continue"
                data-testid="continue-button"
            />
        </form-container>
        <div class="small text-muted mt-2 mb-4">
            By providing your number, you consent to automated or manual marketing messages & to
            <a
                href="https://www.aven.com/docs/TermsOfUse.pdf"
                target="_blank"
                class="blue-link"
            >Terms of Service</a>. Consent to marketing messages is not required for any purchase. Text 'stop' to
            (415) 610-8313 to opt out.
        </div>
        <form-button
            v-if="isDeveloperModeTurnedOn"
            class="mt-3"
            @click="routeToDeveloperSettings"
            label="Developer Settings"
            data-testid="dev-button"
        />
        <modal
            v-if="!!visibleDisclosure"
            :title="visibleDisclosure.title"
            :show="!!visibleDisclosure"
            @close="toggleVisibleDisclosure"
        >
            <div v-html="visibleDisclosure.body" />
        </modal>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import {
        requestTwoFactor,
        RequestTwoFaErrors,
        routeToAuthFlow,
        AuthFlow,
        getSessionInfo,
        validateInviteCode,
        checkHasGottenRefereeBonus,
        postGoogleOfflineConversion,
        postFacebookOfflineConversion,
    } from '@/services/api'
    import { logger } from '@/utils/logger'
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import { mapFields } from 'vuex-map-fields'
    import Modal from '@/components/Modal'
    import { onboardingRoutePaths } from '@/routes/onboardingRoutes'
    import { phoneNumberDisclosure } from 'aven_shared/src/legal/evenFinancialDisclosures'
    import { getOnboardingRoutePath, routeToNotificationIfPermsNotGranted } from '@/utils/navigationUtil'
    import { endTimeToInteractiveTimer } from '@/utils/timeToInteractive'
    import { retrieveDeveloperModeStatus, retrieveNUXTestModeStatus, trySendAppsFlyerPhoneNumberSubmitSuccessEvent } from '@/nativeWebInteraction/nativeWebInteraction'
    import { appRoutePaths } from '@/routes/appRoutes'
    import { sharedRoutePaths } from '@/routes/sharedRoutes'
    import FormField from 'aven_shared/src/components/FormField'
    import inspect from 'util-inspect'
    import { signInRoutePaths } from '@/routes/signInRoutes'
    import _ from 'lodash'
    import { logEvent } from '@/utils/http-client'
    import { FacebookPixelStep, GoogleConversionName, HomeOwnershipStatus } from 'aven_types'
    import { canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess } from '@/nativeWebInteraction/nativeFeatureAvailability'

    const GENERIC_ERROR = 'Something went wrong. Please make sure your phone number is correct and try again!'
    const ACCOUNT_BLOCKED_ERROR = 'Your account is blocked'

    export default {
        name: 'Landing',
        components: { FormField, FormContainer, FormButton, FormFieldPhone, OnboardingLayout, Modal },
        data: function () {
            return {
                submitting: false,
                errorText: '',
                lastSubmittedPhoneNumber: null,
                visibleDisclosure: null,
                phoneNumberDisclosure,
                isDeveloperModeTurnedOn: false,
                isNUXTestModeTurnedOn: false,
            }
        },
        computed: {
            ...mapFields(['phoneNumber', 'inviteCode', 'signUpGoal', 'homeOwnershipStatus']),
            phoneNumberCannotBeSubmitted: function () {
                // We obviously can't submit when the form is submitting, but we also don't
                // want to resubmit a phone number that our server rejected for some reason.
                return this.submitting || this.phoneNumber === this.lastSubmittedPhoneNumber
            },
            shouldShowPaidReferralsOption: function () {
                return this.$featureFlagConfig.paidReferrals
            },
        },
        mounted: async function () {
            const experimentsOverrides = this.$store.getters.experimentsOverrides
            logger.log(`Experiments overrides: ${JSON.stringify(experimentsOverrides)}`)
            this.isNUXTestModeTurnedOn = await retrieveNUXTestModeStatus()
            const isAccountBlocked = this.$route.query.isAccountBlocked
            this.$logEvent('view_advisor_landing_page', { isAccountBlocked })
            if (isAccountBlocked) {
                logger.log(`Showing Aven Advisor account block error on Landing.vue`)
                this.errorText = ACCOUNT_BLOCKED_ERROR
            }
            await endTimeToInteractiveTimer(this.$route)

            // Keep this as the last thing we do in mounted. In non-native environments, this function
            // prevents execution of the rest of the code in mounted until it's finished.
            this.isDeveloperModeTurnedOn = await retrieveDeveloperModeStatus()
        },
        methods: {
            handleLoginWithEmailClick: async function () {
                this.$logEvent('click_button_choose_login_with_email')
                this.phoneNumber = ''
                await this.$router.push({ path: signInRoutePaths.SIGN_IN_EMAIL })
            },
            handleOnSubmit: async function () {
                try {
                    const phoneNumber = this.phoneNumber
                    logger.log(`Aven Advisor user entered phoneNumber ${phoneNumber} on landing page. Requesting 2FA code.`)

                    const isValid = await this.$refs.signInFormRef.$refs.observer.validate()
                    this.$logEvent('click_button_submit_phone_number', { isValid })
                    if (!isValid) {
                        this.$logEvent('event_aven_advisor_submit_phone_failure')
                        logger.log(`Aven Advisor user did not enter phone number on landing page`)
                        return
                    }

                    this.errorText = ''
                    this.submitting = true

                    if (this.inviteCode) {
                        let { shouldContinue } = await this.validateInviteCode(this.inviteCode)
                        const hasGottenRefereeBonus = await this.checkHasGottenRefereeBonus()
                        shouldContinue = shouldContinue && !hasGottenRefereeBonus
                        if (!shouldContinue) {
                            return
                        }
                    }

                    // Keep track of the last submitted phone number so we can stop the user from submitting the
                    // same phone number over and over when the server tells us there's something wrong with it.
                    this.lastSubmittedPhoneNumber = phoneNumber
                    const response = await requestTwoFactor({
                        phoneNumber,
                    })

                    if (!response.data.success) {
                        this.$logEvent('event_aven_advisor_submit_phone_failure')
                        if (response.data.error === RequestTwoFaErrors.TOO_MANY_2FA_REQUESTS) {
                            this.errorText = 'Too many attempts, please try again in 10 minutes'
                        } else {
                            this.errorText = GENERIC_ERROR
                        }
                        logger.log(`Failed to request a 2FA code for phone ${phoneNumber}. Error ${response.data.error}`)
                        return
                    }

                    // Get phone number based experiments by updating session info with phone number
                    window.onboardingPhoneNumber = phoneNumber
                    await getSessionInfo()

                    logger.log(`Received Aven Advisor 2FA code for phone ${phoneNumber}`)
                    this.$store.commit('updateOtpSid', {
                        otpSid: response.data.payload.sid,
                    })
                    this.$store.commit('updateEsignConsentGitHash', { hash: process.env.VUE_APP_RELEASE_HASH })
                    this.$store.commit('updateConsentToMethodFiTosGitHash', { hash: process.env.VUE_APP_RELEASE_HASH })

                    this.$logEvent('click_button_submit_aven_advisor_app_phone_number')
                    if (this.homeOwnershipStatus === HomeOwnershipStatus.OWN) {
                        await postGoogleOfflineConversion(phoneNumber, GoogleConversionName.advisorHomeownerLead)
                    }

                    const routeResponse = await routeToAuthFlow(phoneNumber)
                    if (!routeResponse.data.success) {
                        // Currently this should never happen. If the API call succeeds, routeResponse.data.success will
                        // be true. However, to account for the future possibility that isn't the case, we'll include
                        // basic error handling.
                        this.errorText = GENERIC_ERROR
                        logger.error(`Failed to route to auth on Aven Advisor. Error ${routeResponse.data.error}`)
                        this.$logEvent('event_aven_advisor_submit_phone_failure', { error: routeResponse.data.error })
                        return
                    }
                    this.$logEvent('event_aven_advisor_submit_phone_success')
                    if (this.isNUXTestModeTurnedOn) {
                        // If we're in NUX test mode, even if the phone number is already registered, we want to allow user to go through onboarding again
                        this.$store.commit('setAuthFlow', { authFlow: AuthFlow.ONBOARDING })
                        await this.routeToAuthFlow(AuthFlow.ONBOARDING)
                    } else {
                        // By default, we route to the auth flow that the server tells us to.
                        this.$store.commit('setAuthFlow', { authFlow: routeResponse.data.payload.authFlow })
                        if (routeResponse.data.payload.authFlow === AuthFlow.ONBOARDING) {
                            await postFacebookOfflineConversion(phoneNumber, FacebookPixelStep.PhoneNumberSubmit)
                            if (canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess()) {
                                trySendAppsFlyerPhoneNumberSubmitSuccessEvent()
                            }
                        }
                        await this.routeToAuthFlow(routeResponse.data.payload.authFlow)
                    }
                } catch (e) {
                    // Assume the server never received the number, so we'll allow resubmission. In practice this might
                    // not be exactly correct (e.g. an invalid number could cause the server to return a 500), but this
                    // helps us in the case of a network error, for example, because it lets the user resubmit.
                    this.lastSubmittedPhoneNumber = null
                    this.errorText = GENERIC_ERROR
                    logger.fatal(`Error submitting phone number on Aven Advisor landing page`, e)
                } finally {
                    this.submitting = false
                }
            },
            togglePhoneNumberDisclosure: function () {
                this.toggleVisibleDisclosure(phoneNumberDisclosure)
            },
            toggleVisibleDisclosure: function (disclosure) {
                this.visibleDisclosure = this.visibleDisclosure ? null : disclosure
                if (this.visibleDisclosure) {
                    this.$logEvent('click_button_advisor_disclosure', {
                        type: disclosure.type,
                        text: disclosure.body,
                    })
                }
            },
            routeToAuthFlow: async function (authFlow) {
                // When the account exists w/ a password, the flow is phone -> Push perms -> OTP -> password, so we'll go to OTP next
                if (authFlow === AuthFlow.SIGN_IN) {
                    logger.log('Aven Advisor user has an account, so moving through sign in flow')
                    await routeToNotificationIfPermsNotGranted(this.$router, sharedRoutePaths.ENABLE_NOTIFICATION_SIGN_IN, sharedRoutePaths.OTP_VERIFICATION)
                }
                // When the account exists w/ out a password, and indicating from OG reject, the flow is phone -> Push perms -> OTP -> ssn/dob -> new password, so we'll go to OTP next
                // This flow is currently ONLY for people who are denied in origination and automatically made an Advisor account
                else if (authFlow === AuthFlow.OG_REJECT_ONBOARDING) {
                    logger.info('Aven Advisor user has an account, but no password, and has sourceApplicantId, so moving through OG reject onboarding flow')
                    await routeToNotificationIfPermsNotGranted(this.$router, sharedRoutePaths.ENABLE_NOTIFICATION_PASSWORD_RESET, sharedRoutePaths.OTP_VERIFICATION)
                }
                // When the account exists w/ out a password, and not from OG reject, then the user finished most of the onboarding -
                // but hasn't set the password. The flow is phone -> Push perms -> OTP -> ssn/dob -> new password, so we'll go to OTP next
                else if (authFlow === AuthFlow.CONTINUE_ONBOARDING) {
                    logger.info('Aven Advisor user has an account, but no password, and has no sourceApplicantId, so moving through continue onboarding flow')
                    await routeToNotificationIfPermsNotGranted(this.$router, sharedRoutePaths.ENABLE_NOTIFICATION_PASSWORD_RESET, sharedRoutePaths.OTP_VERIFICATION)
                }
                // For backward compat
                else if (authFlow === AuthFlow.PASSWORD_RESET) {
                    logger.log('Aven Advisor user has an account, but no password, so moving through password reset flow')
                    await routeToNotificationIfPermsNotGranted(this.$router, sharedRoutePaths.ENABLE_NOTIFICATION_PASSWORD_RESET, sharedRoutePaths.OTP_VERIFICATION)
                }
                // When the account doesn't exist, the flow is phone -> multiple data collection pages -> OTP -> password,
                // so we'll start the data collection with the name page.
                else if (authFlow === AuthFlow.ONBOARDING) {
                    logger.log('Aven Advisor user does not have an account, so moving through onboarding flow')

                    const potentialPath = await getOnboardingRoutePath(sharedRoutePaths.ROOT, this)
                    if (potentialPath) {
                        return await this.$router.push({ path: potentialPath })
                    }

                    await this.$router.push({ path: onboardingRoutePaths.FULL_NAME })
                } else {
                    logger.fatal(`Unexpected Aven Advisor auth flow: ${authFlow}`)
                }
            },
            routeToDeveloperSettings: async function () {
                await this.$router.push(appRoutePaths.SIGNED_OUT_DEVELOPER_SETTINGS)
            },
            validateInviteCode: async function () {
                logger.log(`Validating invite code ${this.inviteCode}`)
                try {
                    const validateInviteCodeResponse = await validateInviteCode(this.inviteCode)
                    this.$logEvent('event_advisor_lead_entered_invite_code', { inviteCode: this.inviteCode })

                    if (!validateInviteCodeResponse.data.payload.isValid) {
                        logger.log(`Invite code ${this.inviteCode} is not valid`)
                        this.errorText = validateInviteCodeResponse.data.payload.message ?? `Invite code ${this.inviteCode} is not valid. Please enter a different invite code.`
                        this.$logEvent('event_advisor_lead_entered_invite_code_failure', { inviteCode: this.inviteCode })
                        return { shouldContinue: false }
                    }
                    logger.log(`Invite code ${this.inviteCode} is valid.`)
                    this.$logEvent('event_advisor_lead_entered_invite_code_success', { inviteCode: this.inviteCode })
                    return { shouldContinue: true }
                } catch (error) {
                    logger.fatal(`Error validating invite code`, error)
                    this.$logEvent('event_advisor_lead_entered_invite_code_failure', { inviteCode: this.inviteCode, error: inspect(error) })

                    // Allow the user to continue forward, even if we failed to validate the invite code to minimize disruption to onboarding
                    return { shouldContinue: true }
                }
            },
            checkHasGottenRefereeBonus: async function () {
                try {
                    const response = await checkHasGottenRefereeBonus(this.phoneNumber)
                    if (response.data.payload.hasGottenRefereeBonus) {
                        logger.log(`User has already gotten referee bonus`)
                        this.errorText = 'The phone number you entered has already received a referral bonus. Please enter a different phone number.'
                        this.$logEvent('event_advisor_lead_entered_invite_code_failure', { inviteCode: this.inviteCode })
                    }
                    return response.data.payload.hasGottenRefereeBonus
                } catch (e) {
                    logger.fatal(`Error checking if user has gotten referee bonus`, e)
                    return false
                }
            },
            onPhoneNumberInput: (() => {
                let hasStartedTyping = false
                const debouncedFinishTyping = _.debounce((phoneNumber) => {
                    if (phoneNumber.length === 10) {
                        logEvent('event_aven_advisor_finished_typing_phone')
                    }
                }, 500)

                return (phoneNumber) => {
                    if (!hasStartedTyping && phoneNumber.length >= 1) {
                        hasStartedTyping = true
                        logEvent('event_aven_advisor_started_typing_phone')
                    }

                    debouncedFinishTyping(phoneNumber)
                }
            })(),
        },
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: underline !important;
    }
    .blue-link {
        color: #0054d5;
    }
    .gray-link {
        color: $gray-400;
    }
</style>
